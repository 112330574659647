.vveModal {
    width: 74.8rem;
    background: $white;
    border-radius: 16px;
    z-index: 1071;
    max-width: 74.8rem;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;

    @include media($device) {
        width: calc(100vw - 5rem);
    }

    @include media($mobile) {
        width: calc(100vw - 5rem);
    }

    .modal-content {
        border-radius: 16px;
        .modal-body {
            .error-modal-header {
                @include media($mobile) {
                    margin-bottom: 2rem;
                    line-height: 38px;
                    font-size: 29px;
                    letter-spacing: -1px;
                }
            }
        }

        .error-modal-agree {
            text-transform: uppercase;
          }
    }
}