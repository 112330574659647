.vve-choose-name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 46.1rem;
    align-self: center;
    margin-top: 20.2rem;
    margin-bottom: 2rem;

    @include media($device) {
        margin-top: 14.8rem;
        padding: 2rem;
    }

    @include media($desktop-small) {
        margin-top: 18.7rem;
    }

    @include media($desktop-medium) {
        margin-top: 18.7rem;
    }

    .vve-title {
        font-size: 2.6rem;
        line-height: 2.4rem;
        letter-spacing: 0.03em;
        color: $consent-font-color;
        @extend .vve-book;
    }

    .vve-caution {
        width: 100%;
        margin-top: 1.5rem;
        margin-bottom: 3.2rem;
        font-size: 1.4rem;
        line-height: 1.7rem;
        color: $consent-font-color;
        @extend .vve-light;

        @include media($device) {
            height: 4.8rem;
            color: $consent-font-color;
        }
    }

    .vve-name-field-container {
        width: 100%;
        label {
            font-size: 1.4rem;
            line-height: 2.4rem;
            letter-spacing: -0.03em;
            color: $consent-font-color;
            @extend .vve-light;
        }

        .vve-name-input {
            position: relative;
            width: 100%;
            border: 0.1rem solid $grey;
            border-radius: 1rem;
            overflow: hidden;
            display: flex;
            align-items: center;

            .vve-input {
                flex: 1;
                height: 4.5rem;
                border: none;
                outline: none;
                padding-left: 1.9rem;
                padding-right: 0.5rem;
                font-size: 1.6rem;
                color: $consent-font-color;
                @extend .vve-book;
            }

            .vve-clear-container {
                width: 3.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .vve-clear {
                    width: 1.3rem;
                    height: 1.3rem;
                    background-repeat: no-repeat;
                    background-image: url('data:image/svg+xml,<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.354492"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.5C0 2.91037 2.91037 0 6.5 0C10.0896 0 13 2.91037 13 6.5C13 10.0896 10.0896 13 6.5 13C2.91037 13 0 10.0896 0 6.5ZM9.04962 8.28371L7.26592 6.5C7.26592 6.5 8.96675 4.79971 9.04962 4.71629C9.26142 4.50504 9.26142 4.16217 9.04962 3.95037C8.83837 3.73858 8.4955 3.73858 8.28371 3.95037L6.5 5.73408C6.5 5.73408 4.79971 4.03325 4.71629 3.95037C4.50504 3.73858 4.16217 3.73858 3.95037 3.95037C3.73858 4.16162 3.73858 4.5045 3.95037 4.71629L5.73408 6.5C5.73408 6.5 4.03325 8.20029 3.95037 8.28371C3.73858 8.49496 3.73858 8.83783 3.95037 9.04962C4.16162 9.26142 4.5045 9.26142 4.71629 9.04962L6.5 7.26592C6.5 7.26592 8.20029 8.96675 8.28371 9.04962C8.49496 9.26142 8.83783 9.26142 9.04962 9.04962C9.26142 8.83837 9.26142 8.4955 9.04962 8.28371Z" fill="%238E8E93"/></g></svg>');
                    border: none;
                    background-color: transparent;
                }
            }
            &.textBoxInlineError {
                .vve-name-input {
                    border: 0.1rem solid $validation-error-color;
                    width: 100%;
                }   
            }
        }
    }

    .vve-error-message {
        color: $validation-error-color;
        font-size: 1.2rem;
    }

    .vve-button-div {
        margin-top: 1.5rem;
        width: 100%;
        flex-direction: column;
        align-items: center;

        button {
            width: 100%;
            height: 4.5rem;
            border-radius: 1rem;
            font-size: 1.6rem;
            line-height: 1.6rem;
            letter-spacing: -0.01em;
            @extend .vve-medium;
            background: $white;
            border: 0.1rem solid $btn-color;
            color: $btn-color;


            &.vve-preferred-name {
                color: $white;
                background: $btn-color;
                cursor: pointer;

                &:hover {
                    background-color: $provider-indicator;
                }
            }

            &.vve-preferred-name-disabled {
                background: $btn-color-disabled;
                color: $btn-color-disabled-text;
                border: 0.1rem solid $btn-color-disabled;
                cursor: pointer;
            }
        }
    }
    
}