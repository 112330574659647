.modal-open {
    .fade {
      opacity: 1;
    }
    .modal-dialog {
        margin: 0rem;
        position: relative;
        width: auto;

        @include media($mobile) {
            margin: 1rem;
            position: relative;
            width: auto;
        }
    }
    .vveModal {
        @include media($mobile) {
            left: 47%;
        }
        @include media($device) {
            width: calc(100vw - 5rem);
        }
    }
    .modal-header {
        min-height: auto;
    }
    .modal-backdrop.fade {
        opacity: 0.5;
    }
}