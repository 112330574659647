.vve-launcher-bg {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 36.2rem;
    position: absolute;

    &.vve-launcher-bg-mobile {
        height: 9.4rem;
    }
}

.vve-launcher-logo {
    background-repeat: no-repeat;
    background-size: contain;
    height: 248px;
    width: 385px;
    margin: auto;
    z-index: 1;

    @include media($desktop-small) {
        background-position: 50%;
        position: absolute;
        top: 6rem;
        width: 100%;
     }

     @include media($desktop-medium) { 
        background-position: 50%;
        position: absolute;
        top: 10rem;
        width: 100%;
     }

    &.vve-launcher-logo-mobile {
        height: 16.3rem;
        width: 100%;
        position: absolute;
        top: 3rem;
        z-index: 0;
        background-position: center center;
    }
}

.vve-web-launcher-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media($desktop-small) {
        align-self: center;
        width: 32rem;
        display: flex;
        margin-top: 21rem;
        h1.vve-tab-title{
            font-size: 2.8rem;
        }
    }

    @include media($desktop-medium) {
        align-self: center;
        width: 32rem;
        display: flex;
        margin-top: 21rem;
        h1.vve-tab-title{
            font-size: 2.8rem;
        }
    }

    h1 {
        color: $consent-font-color;
        font-size: 42px;
        line-height: 42px;
        letter-spacing: -0.091rem;
        margin-bottom: 3.8rem;
        text-align: center;
    }

    .vve-button-div {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        align-items: center;

        button {
            width: 32rem;
            height: 4.8rem;
            border-radius: 1rem;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.01em;
            @extend .vve-medium;
            background: $white;
            border: 1px solid $btn-color;
            color: $btn-color;


            &.vve-launch-zoom {
                color: $white;
                background: $btn-color;
                cursor: pointer;

                &:hover {
                    background-color: $provider-indicator;
                }
            }

            &.vve-continue-browser {
                background: $white;
                border: 0.15rem solid $btn-color;
                cursor: pointer;

                &:hover {
                    border: 0.2rem solid $provider-indicator;
                    color: $provider-indicator
                }
            }
        }

        .vve-download-text {
            margin: .7rem 0 0 0;
            font-size: 1.4rem;
            line-height: 1.4rem;
            color: $consent-font-color;
            @extend .vve-book;
        }

        .vve-anchorlink-text {
            color: $dark-blue;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.vve-mobile-launcher-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;

    h1 {
        color: $consent-font-color;
        font-size: 2.8rem;
        line-height: 3.8rem;
        letter-spacing: -0.091rem;
        margin: 0 2.3rem;
        margin-bottom: 2.3rem;
        text-align: center;
        @extend .vve-medium;
    }

    .vve-download-text {
        text-align: center;
    }

    .vve-kp-mobile-download-container {
        margin-top: 4.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: $consent-font-color;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.91px;
            margin-top: 0;
            margin-bottom: 0;
            @extend .vve-medium;
        }

        .vve-anchorlink-text {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.91px;
            margin-top: 0.2rem;
            margin-bottom: 1.6rem;
            text-decoration: none;
            color: $kp-blue;
            @extend .vve-medium;
            cursor: pointer;
            border: none;
            background: none;
        }
    }

    .vve-button-div {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        align-items: center;

        button {
            width: 32rem;
            height: 4.8rem;
            border-radius: 1rem;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.01em;
            @extend .vve-medium;
            background: $white;
            border: 1px solid $btn-color;
            color: $btn-color;

            &.vve-launch-kp-app {
                color: $white;
                background: $btn-color;
                cursor: pointer;

                &:hover {
                    background-color: $provider-indicator;
                }
            }

            &.vve-launch-zoom-app {
                background: $white;
                border: 0.15rem solid $btn-color;
                cursor: pointer;

                &:hover {
                    border: 0.2rem solid $provider-indicator;
                    color: $provider-indicator
                }
            }

        }

        .vve-download-text {
            margin-top: .7rem;
            margin-bottom: 0;
            font-size: 1.2rem;
            line-height: 1.4rem;
            cursor: pointer;
            color: $consent-font-color;
        }
    }
}