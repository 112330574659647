.vve-language-select {
    width: 6.2rem;
    color: $btn-color;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    cursor: pointer;
    @extend .vve-book;
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: -2%;
}