.header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  padding: 2rem 3rem 0 3rem;
  z-index: 1;

  @include media($desktop-small) {
    justify-content: center;
    margin-bottom: 0;
    height: 13rem;
    z-index: 2;

    .vve-header-Logo {
      background-position: center;
      margin-bottom: 30px;
    }
  }

  @include media($desktop-medium) {
    justify-content: center;
    height: 13rem;
    z-index: 2;

    .vve-header-Logo {
      background-position: center;
      margin-top: 5rem;
    }
  }

  @include media($mobile) {
    flex-direction: column;
    padding: 1rem 3rem 0 3rem;
  }

  .vve-header-Logo {
    width: calc(100% - 120px);
    background-size: contain;
    background-repeat: no-repeat;
    height: 46px;
    @include media($mobile) {
      width: 100%;
      background-position: center;
    }
  }
}

.vve-toggle-lang-container {
  margin-right: 5.4rem;
  align-self: flex-end;

  @include media($mobile) {
    margin: 0;
    margin-top: 3rem;
    align-self: flex-end;
  }

  @include media($desktop-small) {
    position: absolute;
    top: 11rem;
    right: 3rem;
  }

  @include media($desktop-medium) {
    position: absolute;
    top: 15rem;
    right: 4rem;
  }
}