.vve-agreement-page {
    font-size: 2rem;
    letter-spacing: -0.02em;
    color: $consent-font-color;
    padding: 0 5.8rem 0 7rem;

    @include media($device) {
        padding: 0 1.8rem 0 2.7rem;
    }

    @include media($desktop-small) {
        padding-top: 5rem;
    }

    @include media($mobile) {
        padding: 0 2.4rem;
    }

    h1.vve-h1-text {
        @extend .vve-bold;
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 0 0 2.5rem 0;

        @include media($device) {
            margin-bottom: 1.8rem;
        }

        @include media($mobile) {
            margin-bottom: 5rem;
        }
    }

    .vve-hidden-h1:not(:focus):not(:active) {
        height: 0.1rem;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 0.1rem;
    }

    p {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;

        @include media($device) {
            margin-bottom: 5rem;
        }
    }

    a {
        color: $btn-color;
        text-decoration-skip-ink: none;
        white-space: nowrap;
    }

    .Agreement-list {
        margin: 0;
        padding-right: 3.2rem;

        li {
            line-height: 24px;
            padding: 1.2rem;
            padding-left: 0.1rem;
            overflow-wrap: anywhere;
        }

        @include media($mobile) {
            padding-right: 0;
            margin-bottom: 5rem;
        }

        @include media($device) {
            padding-right: 0;
            margin-bottom: 5rem;
        }
    }

    .vve-Agreement-subHeading {
        padding-right: 3.2rem;

        @include media($mobile) {
            overflow-wrap: anywhere;
            padding-right: 0;
        }
    }

    .vve-agreement-noList {
        padding: 0;

        li {
            list-style-type: none;
        }
    }

    .vve-agreement-button-div {
        display: flex;
        justify-content: flex-end;
        margin: 5.3rem 0 13.2rem 0;

        @include media($desktop-medium) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @include media($desktop-small) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @include media($device) {
            margin: 9rem 0 8rem 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        button {
            width: 20.1rem;
            height: 4.8rem;
            @extend .vve-bold;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            cursor: pointer;
            border-radius: 1rem;

            &.vve-modal-decline {
                background: $white;
                border: 0.2rem solid $btn-color;
                color: $btn-color;
                margin-right: 4.8rem;
                order: 1;

                &:hover {
                    border: 0.2rem solid $provider-indicator;
                    color: $provider-indicator
                }

                @include media($desktop-medium) {
                    order: 2;
                    margin-right: 0;
                    margin-bottom: 4.4rem;
                    width: 32rem;
                }

                @include media($desktop-small) {
                    order: 2;
                    margin-right: 0;
                    margin-bottom: 4.4rem;
                    width: 32rem;
                }

                @include media($device) {
                    order: 2;
                    margin-right: 0;
                    margin-bottom: 3.6rem;
                }
            }

            &.vve-modal-agree {
                background: $btn-color;
                color: $white;
                border: none;
                order: 2;

                &:hover {
                    background-color: $provider-indicator;
                }

                @include media($desktop-medium) {
                    order: 1;
                    margin-bottom: 4.4rem;
                    width: 32rem;
                }

                @include media($desktop-small) {
                    order: 1;
                    margin-bottom: 4.4rem;
                    width: 32rem;
                }

                @include media($device) {
                    order: 1;
                    margin-bottom: 3.6rem;
                }
            }
        }
    }
}