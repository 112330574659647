.vveModal {
    width: 74.8rem;
    background: $white;
    border-radius: 16px;
    z-index: 1071;
    max-width: 74.8rem;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include media($device) {
        width: calc(100vw - 5rem);
    }

    @include media($mobile) {
        width: calc(100vw - 5rem);
    }

    .modal-content {
        border-radius: 16px;

        .modal-header {
            border-bottom: none;
            margin: 3.8rem 4rem 0 0;
            padding: 0;

            @include media($mobile) {
                margin: 3.8rem 4rem 2rem 0;
            }

            .btn-close {
                opacity: 1;
                padding: 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.50178 7.49843C8.94853 6.94519 8.05155 6.94519 7.49831 7.49843C6.94506 8.05167 6.94506 8.94865 7.49831 9.5019L14.9966 17.0002L7.49831 24.4984C6.94506 25.0517 6.94506 25.9487 7.49831 26.5019C8.05155 27.0551 8.94853 27.0551 9.50178 26.5019L17 19.0036L24.4983 26.5019C25.0515 27.0551 25.9485 27.0551 26.5018 26.5019C27.055 25.9487 27.055 25.0517 26.5018 24.4984L19.0035 17.0002L26.5018 9.5019C27.055 8.94866 27.055 8.05167 26.5018 7.49843C25.9485 6.94519 25.0516 6.94519 24.4983 7.49843L17 14.9967L9.50178 7.49843Z' fill='%23003B71'/%3E%3C/svg%3E");
                background-size: 3rem 3rem;
                width: 2rem;
                height: 2rem;

                @include media($device) {
                    background-size: 4rem 4rem;
                    height: 2rem;
                    width: 2rem;
                }

                @include media ($mobile) {
                    background-size: 4rem 4rem;
                    height: 2rem;
                    width: 2rem;
                }

                &:active,
                &:hover,
                &:focus {
                    border: 0px solid $white;
                    box-shadow: none;
                }
            }
        }

        .modal-body {
            border-radius: 16px;
            padding: 0 8rem 0 4.4rem;

            @include media($device) {
                padding: 0 3.8rem 0 4.4rem;
            }

            @include media($mobile){
                padding:0 3rem 3rem 4.4rem;
            }

            .vve-modal-header {
                @extend .vve-light;
                font-size: 3.2rem;
                line-height: 6.4rem;
                color: $consent-font-color;
                margin-bottom: 1.3rem;

                @include media($device) {
                    line-height: 5rem;
                    margin-bottom: 40px;
                    color: $consent-font-color; 
                }

                @include media($mobile) {
                    line-height: 50px;
                    letter-spacing: -1px;
                    margin-bottom: 40px;
                    color: $consent-font-color;
                }
            }

            .vve-modal-body {
                @extend .vve-book;
                font-size: 1.6rem;
                line-height: 2.4rem;
                color: $consent-font-color;
                
                &.error-modal-body{
                    @include media($mobile) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .modal-footer {
            margin: 0;
            padding: 0;
            border-top: none;

            @include media($device) {
                justify-content: flex-end;
                position: relative;
                top: 3rem;
                margin: 1.3rem 6rem 5.3rem 0;
                flex-direction: row;
            }

            @include media($mobile) {
                margin: auto;
                padding-bottom: 4rem;
            }

            .vve-button-div {
                display: flex;
                justify-content: flex-end;
                margin: 5.3rem 6rem 5.3rem 0;

                @include media($device) {
                    flex-direction: row;
                    justify-content: center;
                    margin: 0;
                }

                @include media($mobile) {
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    margin: auto;
                }

                .vve-modal-decline {
                    width: 14.4rem;
                    height: 4.8rem;
                    background: $white;
                    border: 0.15rem solid $btn-color;
                    cursor: pointer;
                    border-radius: 1rem;
                    &:hover {
                        border: 0.2rem solid $provider-indicator;
                        color: $provider-indicator
                    }

                    @extend .vve-medium;
                    font-size: 2rem;
                    line-height: 2rem;
                    text-align: center;
                    color: $btn-color;
                    margin-right: 3rem;

                    @include media($device) {
                        margin-right: 3rem;
                        margin-bottom: 3rem;
                    }

                    @include media($mobile) {
                        margin-right: 0;
                        width: 20.1rem;
                    }
                }

                .vve-modal-agree {
                    width: 14.4rem;
                    height: 4.8rem;
                    background: $btn-color;
                    border: 1px solid $btn-color;
                    @extend .vve-medium;
                    font-size: 2rem;
                    line-height: 2rem;
                    text-align: center;
                    color: $white;
                    border-radius: 1rem;

                    &:hover {
                        background-color: $provider-indicator;
                    }
                    @include media($mobile) {
                        width: 20.1rem;
                        margin-bottom: 3rem;
                    }
                }
            }
        }
    }
}

#vve-Modal {
    .modal-content {
        .modal-body {
            .vve-modal-header {
                letter-spacing: -0.1rem;

                @include media ($mobile) {
                    padding: 0 1rem 0 0;
                }
            }
        }
    }
}