/* app sass variables */
@import './assets/sass/util';
// @import './assets/sass/fonts';
@import './assets/sass/flexbox';

/* bootstrap */
@import "~bootstrap/scss/bootstrap";

/* app styles*/
@import './container/container';
@import './components/components';

html, body {
    min-width: 0 !important;
}
