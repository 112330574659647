$kp-blue:#0078b3;
$dark-indigo: #0d1c3d;
$white: #ffffff;
$dark-blue: #006ba6;
$very-dark-blue: #0a1940;
$black: #000000;
$btn-color: #0078B3;
$btn-color-disabled: #EFEFF4;
$btn-color-disabled-text: #909096;
$provider-indicator: #003b71;
$consent-font-color: #232323;
$grey: #C4C4C4;
$validation-error-color: #D1480C;