//Considers devices like mobiles and Tablets (Ipad Pro 9.7)
$device: "(max-width: 768px)";
//Considers devices landscape Tablets + desktop
$desktop: "(min-width: 769px)";

$mobile: "(max-width: 600px)";
$mobile-landscape: "(max-width: 844px) and (orientation: landscape)";
$desktop-small: "(min-width: 769px) and (max-width: 1024px)";
$desktop-medium: "(min-width: 1025px) and (max-width: 1280px)";
$desktop-large: "(min-width: 1281px)";

@mixin media ($value) {
  @media #{$value} {
    @content;
  }
}